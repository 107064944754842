exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-free-tools-erlang-tsx": () => import("./../../../src/pages/free-tools/erlang.tsx" /* webpackChunkName: "component---src-pages-free-tools-erlang-tsx" */),
  "component---src-pages-free-tools-tsx": () => import("./../../../src/pages/free-tools.tsx" /* webpackChunkName: "component---src-pages-free-tools-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-archive-tsx": () => import("./../../../src/templates/blog-archive.tsx" /* webpackChunkName: "component---src-templates-blog-archive-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

